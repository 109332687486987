import React, { ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
// @ts-ignore
import Siema from 'siema';
import styled from '@emotion/styled';
import { slider, spacer } from '../styles/variables';
import { getRemSize, resetButton } from '../styles/mixins';
import ArrowRight from '../img/arrow_right.module.svg';
import ArrowLeft from '../img/arrow_left.module.svg';
import { breakpointUp } from '../styles/mq';

const StyledSliderContainer = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: ${slider.ratio};
`;

const StyledSlider = styled.div`
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
`;

enum Position {
	left,
	right,
}

type StyledSliderShifterProps = {
	position: Position;
};

const StyledSliderShifter = styled.button`
	${resetButton()};
	position: absolute;
	display: none;
	justify-content: ${({ position }: StyledSliderShifterProps) => (position === Position.right ? 'flex-end' : 'flex-start')};
	align-items: center;
	width: ${getRemSize(64)};
	height: 100%;
	top: 0;
	left: ${({ position }: StyledSliderShifterProps) => (position === Position.left ? `-${spacer['7']}` : 'auto')};
	right: ${({ position }: StyledSliderShifterProps) => (position === Position.right ? `-${spacer['7']}` : 'auto')};
	padding: 0;

	${breakpointUp('md')} {
		display: flex;
	}

	${breakpointUp('xxl')} {
		left: ${({ position }: StyledSliderShifterProps) => (position === Position.left ? `-${spacer['8']}` : 'auto')};
		right: ${({ position }: StyledSliderShifterProps) => (position === Position.right ? `-${spacer['8']}` : 'auto')};
	}
`;

type Props = {
	children: ReactNode;
	autoplay?: boolean;
	onSlideChange?: (index: number) => void;
};

type SiemaType = {
	prev: (howManySlides?: number) => void;
	next: (howManySlides?: number) => void;
	goTo: (index: number) => void;
	currentSlide: number;
};

export interface SliderType extends SiemaType {
	play: () => void;
	pause: () => void;
}

const Slider = React.forwardRef<SliderType, Props>(({ children, onSlideChange }, ref) => {
	const siema = useRef<SliderType>();
	const [ , setPlay ] = useState(false);

	useEffect(() => {
		siema.current = new Siema({
			perPage: 1,
			onChange: () => {
				if (siema.current && onSlideChange) {
					onSlideChange(siema.current.currentSlide);
				}
			},
		});
	}, []);

	useImperativeHandle(ref, () => ({
		next: (howManySlides?: number) => {
			siema.current && siema.current.next(howManySlides);
		},
		prev: (howManySlides?: number) => {
			siema.current && siema.current.prev(howManySlides);
		},
		goTo: (index: number) => {
			siema.current && siema.current.goTo(index);
		},
		pause: () => {
			setPlay(false);
		},
		play: () => {
			setPlay(true);
		},
		currentSlide: siema.current?.currentSlide || 0,
	}));

	return (
		<StyledSliderContainer>
			<StyledSlider className="siema">{children}</StyledSlider>
			<StyledSliderShifter
				position={Position.left}
				onClick={() => siema.current && siema.current.prev()}
			>
				<ArrowLeft />
			</StyledSliderShifter>
			<StyledSliderShifter
				position={Position.right}
				onClick={() => siema.current && siema.current.next()}
			>
				<ArrowRight />
			</StyledSliderShifter>
		</StyledSliderContainer>
	);
});

export default Slider;
