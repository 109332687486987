import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PageCategoryStructure, PageProps } from '../typing/Page';
import Layout from '../components/Layout';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { breakpoints, fontSize, gutter, spacer } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import CategoriesNav from '../components/CategoriesNav';
import Slider, { SliderType } from '../components/Slider';
import SliderItem from '../components/SliderItem';
import NavLink from '../components/NavLink';
import { getTranslation } from '../utils/translations';
import ProjectCard from '../components/ProjectCard';
import ListGrid from '../components/ListGrid';
import { ProjectList } from '../typing/Project';
import { breakLines, replaceCategoryName } from '../utils/strings';

const StyledTextContainer = styled.div`
	margin-bottom: ${spacer['9']};
	
	${breakpointUp('md')} {
		margin-bottom: ${spacer['11']};
	}

	br {
		display: none;

		${breakpointUp('md')} {
			display: inline;
		}
	}
`;

const StyledText = styled.p`
	margin-bottom: ${spacer['5']};
	font-size: ${fontSize.lg};
	font-weight: bold;
	line-height: 1.35;

	${breakpointUp('md')} {
		margin-bottom: ${spacer['7']};
		font-size: ${fontSize.h2};
	}
`;

const ProjectsCategoriesCss = css`
	margin-bottom: ${spacer['10']};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['13']};
	}
`;

const ProjectsListCss = css`
	margin-bottom: ${spacer['9']};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['11']};
	}
`;

type IndexData = {
	claim: string;
	projectsCategories: PageCategoryStructure[];
	projects: ProjectList[];
};

const IndexTemplate: React.FC<PageProps<IndexData, GatsbyTypes.IndexQuery>> = ({ path, data, pageContext }) => {
	const aboutSlug = pageContext.structure.find(item => item.name === 'about')?.slug;
	const slider = useRef<SliderType>(null);
	const projectsCategories = pageContext.data.projectsCategories;
	const projectsStructure = pageContext.structure.find(item => item.name === 'projects');

	const onProjectCategoryActivate = (navCategory: PageCategoryStructure) => {
		const i = projectsCategories.findIndex(category => category.name === navCategory.name);
		if (i > -1) {
			slider?.current?.goTo(i);
		}
	};

	return (
		<Layout
			path={path}
			pageContext={pageContext}
		>
			<StyledTextContainer>
				<StyledText dangerouslySetInnerHTML={{ __html: breakLines(pageContext.data.claim) }} />
				{aboutSlug && (
					<NavLink
						to={aboutSlug}
						title={getTranslation(data, 'moreAboutUs')}
					/>
				)}
			</StyledTextContainer>
			{projectsCategories && (
				<Slider ref={slider}>
					{projectsCategories.map(projectCategory => {
						const imgDef = data.projectsCategoriesImages.edges.find(edge => edge.node.relativePath === projectCategory.coverImage);
						return imgDef?.node.childImageSharp?.gatsbyImageData ? (
							<SliderItem key={imgDef.node.id}>
								<GatsbyImage
									image={imgDef.node.childImageSharp.gatsbyImageData}
									loading="eager"
									sizes={`(max-width: ${breakpoints.sm - 1}px) calc(100vw - (${gutter.full} * 2)), (max-width: ${breakpoints.md - 1}px) calc(100vw - (${gutter.full} * 2)), (max-width: ${breakpoints.xxxl - 1}px) calc(100vw - (${gutter.md.full} * 2)), 1600px`}
									alt={replaceCategoryName(projectCategory.title)}
								/>
							</SliderItem>
						) : null;
					})}
				</Slider>
			)}
			{projectsCategories && (
				<CategoriesNav
					categories={projectsCategories}
					label={getTranslation(data, 'all')}
					onActivate={onProjectCategoryActivate}
					css={ProjectsCategoriesCss}
				/>
			)}
			<ListGrid
				cols={1}
				rCols={{ sm: 2, xl: 3 }}
				title={getTranslation(data, 'featuredProjects')}
				css={ProjectsListCss}
			>
				{pageContext.data.projects.map((project, i) => {
					const imgDef = data.projectsImages.edges.find(edge => edge.node.relativePath === project.coverImage);
					return (
						<ProjectCard
							key={i.toString()}
							project={project}
							image={imgDef?.node.childImageSharp?.gatsbyImageData}
						/>
					);
				})}
			</ListGrid>
			{projectsStructure && (
				<NavLink
					to={projectsStructure.slug}
					title={getTranslation(data, 'allProjects')}
				/>
			)}
		</Layout>
	);
};

export const query = graphql`
	query Index($lang: String!, $projectsCategoriesImages: [String!], $projectsImages: [String!]) {
		translations: allFile(filter: { name: { eq: $lang }, extension: { eq: "json" } }) {
			nodes {
				childTranslationsJson {
					all
					moreAboutUs
					featuredProjects
					allProjects
				}
			}
		}
		projectsCategoriesImages: allFile(filter: { relativePath: { in: $projectsCategoriesImages } }) {
			edges {
				node {
					id
					relativePath
					childImageSharp {
						gatsbyImageData(width: 1600, height: 900, quality: 80, placeholder: BLURRED, layout: CONSTRAINED)
					}
				}
			}
		}
		projectsImages: allFile(filter: { relativePath: { in: $projectsImages } }) {
			edges {
				node {
					id
					relativePath
					childImageSharp {
						gatsbyImageData(width: 544, height: 306, breakpoints: [272, 544], quality: 80, placeholder: BLURRED, layout: CONSTRAINED)
					}
				}
			}
		}
	}
`;

export default IndexTemplate;
