import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { slider } from '../styles/variables';
import { breakpointUp } from '../styles/mq';

const StyledSliderItemContainer = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: ${slider.ratio};

	// ${breakpointUp('sm')} {
	// 	padding-bottom: ${slider.sm.ratio};
	// }
`;

const StyledSliderItem = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	> img {
		width: 100%;
		height: auto;
	}
`;

type Props = {
	children: ReactNode;
};

const SliderItem: React.FC<Props> = ({ children }) => {
	return (
		<StyledSliderItemContainer>
			<StyledSliderItem>{children}</StyledSliderItem>
		</StyledSliderItemContainer>
	);
};

export default SliderItem;
